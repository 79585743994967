<template>
  <span class="text-center"
    ><span
      data-test="signig-legend-link"
      class="text-teal-primary cursor-pointer font-semibold underline"
      @click="closeAnyAndShowSignIn"
      >Sign in</span
    >
    to see what glasses you qualify for and what your benefits cover.</span
  >
</template>
<script setup lang="ts">
import { useSignInStore } from '~/store/signin'
const { setShowSignIn } = useSignInStore()

const emit = defineEmits(['close'])

const closeAnyAndShowSignIn = () => {
  emit('close')
  setShowSignIn(true)
}
</script>
